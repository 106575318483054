import { useState } from "react";
import { useSetRecoilState } from "recoil";
import styled from "styled-components";
import { CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js";
import { Link, TextInput, ErrorBox } from "@sussex/react-kit/elements";
import { Loading } from "@sussex/react-kit/assets";
import { validateEmail } from "@sussex/react-kit/utils";
import { Heading, Container, ErrorText, Submit } from "./Elements";
import Confirm from "../Confirm";
import userPool from "../../cognito";
import MemoryStorage from "../../memory-storage";
import userState from "../../state/user";
import useCopy from "../../hooks/useCopy";

const LinkText = styled(Link)`
  font-size: ${({ theme }) => theme.fontSize.normal};
`;

const SkipText = styled(LinkText)`
  text-align: center;
  width: 100%;
`;

const SignIn = ({ next, canSkip = true, back, forgotPasswordAction }) => {
  const [
    titleText,
    emailText,
    passwordText,
    genericErrorText,
    submitText,
    forgotText,
    skipText,
    backText,
    invalidEmailText,
    invalidPasswordText,
    credentialsErrorText,
  ] = useCopy([
    "bookNow.signIn.title",
    "bookNow.signIn.email",
    "bookNow.signIn.password",
    "bookNow.signIn.error",
    "bookNow.signIn.signIn",
    "bookNow.signIn.forgotPassword",
    "bookNow.signIn.skip",
    "bookNow.signIn.back",
    "bookNow.signIn.emailError",
    "bookNow.signIn.passwordError",
    "bookNow.signIn.incorrectError",
  ]);
  const setUserState = useSetRecoilState(userState);
  const [confirmUser, setConfirmUser] = useState(false);
  const [user, setUser] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [loginError, setLoginError] = useState(false);
  const [loading, setLoading] = useState(false);
  const emailValid = validateEmail(email);
  const passwordValid = password.length >= 8;

  const signIn = () => {
    setLoading(true);
    const authenticationData = {
      Username: email,
      Password: password,
    };
    const authenticationDetails = new AuthenticationDetails(authenticationData);
    const userData = {
      Username: email,
      Pool: userPool,
      Storage: MemoryStorage,
    };
    const cognitoUser = new CognitoUser(userData);
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: () => {
        cognitoUser.getUserAttributes((err, attrResult) => {
          setLoading(false);

          if (err) {
            console.error("Error getting user attributes", err);
            return;
          }

          let name = "",
            phone = "",
            locale = "";

          attrResult.forEach(a => {
            if (a.Name === "name") {
              name = a.Value;
            }
            if (a.Name === "phone_number") {
              phone = a.Value;
            }
            if (a.Name === "locale") {
              locale = a.Value;
            }
          });

          setUserState({
            signedIn: true,
            verified: true,
            username: email,
            name: name,
            email: email,
            phone: phone,
            locale: locale,
          });

          next();
        });
      },
      onFailure: err => {
        setLoading(false);
        if (err.message === "User is not confirmed.") {
          setUser(cognitoUser);
          setConfirmUser(true);
          return;
        }
        if (err.message === "Incorrect username or password.") {
          setLoginError(credentialsErrorText);
          return;
        }
        setLoginError(genericErrorText);
        console.error(err.message || JSON.stringify(err));
      },
    });
  };

  const checkEmail = () => {
    setEmailError(emailValid ? false : invalidEmailText);
  };

  const checkPassword = () => {
    setPasswordError(passwordValid ? false : invalidPasswordText);
  };

  const handleEmailChange = ({ target }) => {
    setEmailError(false);
    setEmail(target.value);
  };

  const handlePasswordChange = ({ target }) => {
    setPasswordError(false);
    setPassword(target.value);
  };

  if (confirmUser) {
    const authDetails = { email, password };
    return <Confirm authDetails={authDetails} user={user} next={next} />;
  }

  return (
    <Container>
      <Heading>{titleText}</Heading>
      {loginError && (
        <ErrorBox>
          <ErrorText>{loginError}</ErrorText>
        </ErrorBox>
      )}
      {emailError && (
        <ErrorBox>
          <ErrorText>{emailError}</ErrorText>
        </ErrorBox>
      )}
      {passwordError && (
        <ErrorBox>
          <ErrorText>{passwordError}</ErrorText>
        </ErrorBox>
      )}
      <TextInput
        label={emailText}
        value={email}
        type="email"
        onChange={handleEmailChange}
        onBlur={checkEmail}
      />
      <TextInput
        label={passwordText}
        value={password}
        type="password"
        onChange={handlePasswordChange}
        onBlur={checkPassword}
      />
      <LinkText onClick={forgotPasswordAction}>{forgotText}</LinkText>
      <Submit
        type="submit"
        disabled={!(emailValid && passwordValid)}
        onClick={signIn}
      >
        {loading ? <Loading /> : submitText}
      </Submit>
      {canSkip ? (
        <SkipText onClick={next}>{skipText}</SkipText>
      ) : (
        <SkipText onClick={back}>{backText}</SkipText>
      )}
    </Container>
  );
};

export default SignIn;
