import AWS from "aws-sdk";
import {
  KmsKeyringBrowser,
  KMS,
  getClient,
  buildClient,
  CommitmentPolicy,
} from "@aws-crypto/client-browser";
import userPool from "./cognito";
import { fromBase64, toBase64 } from "@aws-sdk/util-base64-browser";
import { getCurrentUser } from "./httpapi";
import md5 from "md5";

const region = process.env.REACT_APP_REGION;
const idp = process.env.REACT_APP_IDP;
const cognitoIDP = process.env.REACT_APP_COGNITO_IDENTITY;
const generatorKeyId = process.env.REACT_APP_KEY_ID;

const { encrypt, decrypt } = buildClient(
  CommitmentPolicy.REQUIRE_ENCRYPT_REQUIRE_DECRYPT,
);

const cache = {};

const getIDToken = async () => {
  let idToken = "";

  try {
    idToken = await new Promise((resolve, reject) => {
      const cognitoUser = userPool.getCurrentUser();
      if (!cognitoUser) {
        return reject("could not get current user");
      }
      cognitoUser.getSession((err, session) => {
        if (err) {
          return reject(err);
        }
        if (!session.isValid()) {
          return reject("session is not valid");
        }
        const token = session.getIdToken().getJwtToken();
        return resolve(token);
      });
    });
  } catch (err) {
    // fallback to the get current user endpoint
    const user = await getCurrentUser();

    if (user) {
      idToken = user.idToken;
    }
  }

  return idToken;
};

export const decryptText = async (credentials, hashedEncrypted) => {
  if (!hashedEncrypted) {
    return "";
  }

  const cacheKey = md5(hashedEncrypted);
  if (cache[cacheKey]) {
    return cache[cacheKey];
  }

  try {
    const encrypted = fromBase64(hashedEncrypted);
    const clientProvider = getClient(KMS, { credentials });
    const keyring = new KmsKeyringBrowser({ generatorKeyId, clientProvider });
    const decrypted = await decrypt(keyring, encrypted);
    const dec = new TextDecoder();
    const decoded = dec.decode(decrypted.plaintext);
    cache[cacheKey] = decoded;
    return decoded;
  } catch (err) {
    console.error(err);
    return "";
  }
};

export const encryptText = async (credentials, message) => {
  try {
    const enc = new TextEncoder();
    const bytes = enc.encode(message);
    const clientProvider = getClient(KMS, { credentials });
    const keyring = new KmsKeyringBrowser({ generatorKeyId, clientProvider });
    const encrypted = await encrypt(keyring, bytes);
    const hashedEncrypted = toBase64(encrypted.result);
    return hashedEncrypted;
  } catch (err) {
    console.error(err);
    return "";
  }
};

export const getIDPCredentials = async () => {
  const idToken = await getIDToken();

  if (!idToken) {
    return null;
  }

  let credentials = null;

  try {
    credentials = await new Promise((resolve, reject) => {
      AWS.config.region = region;

      /**********************************************************************/
      // The following error appears sometimes:
      //
      // "NotAuthorizedException: Logins don't match. Please include at
      // least one valid login for this identity or identity pool."
      //
      // Using this suggestion in order to prevent it:
      // https://github.com/aws/aws-sdk-js/issues/609#issuecomment-102208956
      const params = {
        IdentityPoolId: idp,
        Logins: {
          [cognitoIDP]: idToken,
        },
      };
      let creds = new AWS.CognitoIdentityCredentials(params);
      creds.clearCachedId();
      creds = new AWS.CognitoIdentityCredentials(params);
      AWS.config.credentials = creds;
      /**********************************************************************/

      AWS.config.credentials.get((err, result) => {
        if (err) {
          return reject(err);
        }

        return resolve({
          secretAccessKey: AWS.config.credentials.secretAccessKey,
          accessKeyId: AWS.config.credentials.accessKeyId,
          sessionToken: AWS.config.credentials.sessionToken,
        });
      });
    });
  } catch (err) {
    console.error(err);
  }

  return credentials;
};
