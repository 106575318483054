import {
  useContext,
  useEffect,
  useCallback,
  useRef,
  createContext,
} from "react";
import { postMetrics } from "../httpapi";
import { uuid } from "../utils";

const initiationId = uuid();

const params = new URLSearchParams(window.location.search);
const profileUuid = params.get("profile_uuid");

export const MetricContext = createContext(null);

export const useAddMetric = () => {
  const { addMetric } = useContext(MetricContext);
  return addMetric;
};

const MetricProvider = ({ children }) => {
  const metricsRef = useRef([]);

  const addMetric = useCallback(m => {
    const newMetric = {
      ...m,
      time: Math.floor(Date.now() / 1000),
    };
    metricsRef.current = [...metricsRef.current, newMetric];
  }, []);

  const sendMetrics = useCallback(async () => {
    // Get current metrics and clear metrics
    if (metricsRef.current.length === 0) {
      return;
    }
    const currentMetrics = JSON.parse(JSON.stringify(metricsRef.current));
    metricsRef.current = [];

    try {
      const events = currentMetrics.map(m => ({
        metaData: {
          initiationId,
          profileUuid,
        },
        ...m,
      }));
      await postMetrics(events);
    } catch (e) {
      // If we encounter an error sending metrics add them back in to our state
      metricsRef.current = [...metricsRef.current, ...currentMetrics];
    }
  }, []);

  useEffect(() => {
    const intervalID = setInterval(sendMetrics, 15 * 1000);
    document.addEventListener("visibilitychange", sendMetrics);
    window.addEventListener("pagehide", sendMetrics);

    return () => {
      clearInterval(intervalID);
      document.removeEventListener("visibilitychange", sendMetrics);
      window.document.removeEventListener("pagehide", sendMetrics);
    };
  }, [sendMetrics]);

  return (
    <MetricContext.Provider value={{ addMetric }}>
      {children}
    </MetricContext.Provider>
  );
};

export default MetricProvider;
