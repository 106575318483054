import { useState } from "react";
import styled, { useTheme } from "styled-components";
import { Link, Button, ErrorBox, Pill } from "@sussex/react-kit/elements";
import { swapCopyVariables } from "@sussex/react-kit/utils";
import { formatTimeRange } from "@sussex/react-kit/conversations";
import { DatePickerCalendar } from "react-nice-dates";
import { enUS, es } from "date-fns/locale";
import {
  selector,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from "recoil";
import { formState, stepState, summaryState, STEPS } from "../state";
import providerState from "../../../state/provider";
import userState, {
  activeRequests as requestHistoryState,
  unexpiredRequests as unexpiredRequestsState,
  locale as localeState,
} from "../../../state/user";
import { days, minDate, nextAvailableDayState } from "../../../state/time";
import useCopy from "../../../hooks/useCopy";
import Arrow from "../../../assets/Arrow.js";
import SignInFlow from "../../SignIn/Flow";
import X from "../../../assets/X";
import Prompt from "../Prompt";
import PreviousRequests from "../../PreviousRequests";

import "react-nice-dates/build/style.css";

export const formTimeState = selector({
  key: "FormData.Time",
  get: ({ get }) => get(formState).time,
  set: ({ set }, newValue) => set(formState, s => ({ ...s, time: newValue })),
});

const getLocale = langCountry => {
  const locales = {
    "en-US": enUS,
    "es-US": es,
    en: enUS,
    es: es,
  };

  // Try exact match
  if (typeof locales[langCountry] !== "undefined") {
    return locales[langCountry];
  }

  // Try Country Code
  const lang = langCountry.split("-")[0];

  /**
   * default language
   */
  if (typeof locales[lang] === "undefined") {
    return enUS;
  }

  return locales[lang];
};

const Wrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.border};
  padding: 24px;
  border-radius: 10px;
`;

const Break = styled.hr`
  border: none;
  border-top: 1px solid ${({ theme }) => theme.colors.border};
  margin: 24px 0;
`;

const XWrapper = styled.div`
  margin-left: 10px;
  display: flex;
  align-items: center;
`;

const AccountTextWrapper = styled.div`
  margin-top: 16px;
  line-height: 22px;
  font-size: ${({ theme }) => theme.fontSize.large};
`;

const AccountTextHeader = styled.div`
  font-family: ${({ theme }) => theme.fonts.semiBold};
`;

const AccountTextBody = styled.p`
  font-family: ${({ theme }) => theme.fonts.primary};
  margin: 0;
`;

const NextButton = styled(Button)`
  width: 100%;
  height: 56px;
`;

const TimeWrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 10px;
  padding: 24px;
`;

const PillWrapper = styled.div`
  > * {
    margin: 16px 0;
  }
`;

const Heading = styled.div`
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const SlotName = styled.div`
  margin-bottom: 7px;
`;

const Day = styled.a`
  font-family: ${({ theme }) => theme.fonts.semiBold};
  cursor: pointer;

  > * {
    margin: 0 7px;
  }
`;

const DatePickerWrapper = styled.div`
  position: absolute;
  top: 160px;
  right: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-sizing: border-box;
  background: white;
  padding: 15px;
  margin: 15px auto;
  border-radius: 8px;
  width: 250px;
  border: 1px solid ${({ theme }) => theme.colors.border};
`;

const ArrowWrapper = styled.div`
  cursor: pointer;
  height: 30px;
  width: 30px;
  border-radius: 25px;
  border: 1px solid
    ${({ disabled, theme }) =>
      disabled ? theme.colors.border : theme.colors.dark};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ConfirmTimeWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Prev = ({ onClick, disabled }) => {
  const theme = useTheme();

  return (
    <ArrowWrapper onClick={disabled ? null : onClick} disabled={disabled}>
      <Arrow
        direction="left"
        color={disabled ? theme.colors.border : theme.colors.dark}
      />
    </ArrowWrapper>
  );
};

const Next = ({ onClick }) => {
  const theme = useTheme();

  return (
    <ArrowWrapper onClick={onClick}>
      <Arrow direction="right" color={theme.colors.dark} />
    </ArrowWrapper>
  );
};

const TimeSlot = styled(Pill)`
  text-transform: lowercase;
`;

const NoTimeWrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 10px;
  padding: 24px;
  text-align: center;
`;

const NoTimesAvailable = () => {
  const [noneAvailable] = useCopy(["bookNow.steps.time.noneAvailable"]);
  return <NoTimeWrapper>{noneAvailable}</NoTimeWrapper>;
};

const ConfirmTime = ({ next, disabled }) => {
  const [showSignIn, setShowSignIn] = useState(false);
  const [time, setTime] = useRecoilState(formTimeState);
  const userData = useRecoilValue(userState);
  const locale = useRecoilValue(localeState);
  const isSummary = useRecoilValue(summaryState);
  const setCurrentStep = useSetRecoilState(stepState);
  const { timezone } = useRecoilValue(providerState);
  const start = new window.Date(time.from);
  const stop = new window.Date(time.to);
  const [signInText, nextText, haveAccountText, signInWithAccountText] =
    useCopy([
      "bookNow.signIn.signInLink",
      "bookNow.steps.next",
      "bookNow.steps.time.haveAccount",
      "bookNow.steps.time.signInWithAccount",
    ]);

  const cancel = () => {
    //navigate back
    setTime(null);
  };

  const signInNext = () => {
    setShowSignIn(false);
  };

  const SignInLink = () => (
    <Link onClick={() => setShowSignIn(true)}>{signInText}</Link>
  );

  const Next = ({ disabled }) => (
    <div>
      <NextButton disabled={disabled} onClick={next} type="submit">
        {nextText}
      </NextButton>
      {(!userData.signedIn || !userData.verified) && (
        <AccountTextWrapper>
          <AccountTextHeader>{haveAccountText}</AccountTextHeader>
          <AccountTextBody>
            {swapCopyVariables(signInWithAccountText, {
              LINK: <SignInLink />,
            })}
          </AccountTextBody>
        </AccountTextWrapper>
      )}
    </div>
  );

  const ConfirmFooter = () => {
    if (showSignIn) {
      return <SignInFlow next={signInNext} />;
    }

    if (userData.signedIn && userData.verified && !isSummary) {
      return (
        <PreviousRequests
          disabled={disabled}
          onSkip={next}
          onSelect={() => {
            setCurrentStep(STEPS.SUMMARY);
          }}
        />
      );
    }

    return <Next />;
  };

  return (
    <Wrapper>
      <Pill selected={true} onClick={cancel}>
        <ConfirmTimeWrapper>
          <span>
            {start.toLocaleDateString(locale, {
              day: "numeric",
              month: "long",
            })}
            {", "}
            {formatTimeRange(start, stop, timezone, locale)}
          </span>
          <XWrapper>
            <X />
          </XWrapper>
        </ConfirmTimeWrapper>
      </Pill>
      <Break />
      <ConfirmFooter />
    </Wrapper>
  );
};

const TimeBox = ({ onSelect }) => {
  const { availability, timezone } = useRecoilValue(providerState);
  const locale = useRecoilValue(localeState);
  const nextAvailableDay = useRecoilValue(nextAvailableDayState);
  const [date, setDate] = useState(nextAvailableDay);
  const [datePickerVisible, setDatePickerVisible] = useState(false);
  const dayDifference = Math.round(
    (date.getTime() - new window.Date().getTime()) / (1000 * 3600 * 24),
  );
  const [morningText, afternoonText, eveningText] = useCopy([
    "bookNow.steps.time.morning",
    "bookNow.steps.time.afternoon",
    "bookNow.steps.time.evening",
  ]);
  const timeCopy = {
    morning: morningText,
    afternoon: afternoonText,
    evening: eveningText,
  };
  const weekNum = Math.floor(dayDifference / 7);
  const dayOfWeek = days[date.getDay()];
  const availableTimes = availability[dayOfWeek] || {};
  const prevDisabled =
    date.toISOString().split("T")[0] ===
    new window.Date().toISOString().split("T")[0];
  const prevDay = () => {
    const prev = new window.Date(date);
    prev.setDate(prev.getDate() - 1);
    setDate(prev);
  };
  const showDatePicker = () => {
    setDatePickerVisible(!datePickerVisible);
  };
  const nextDay = () => {
    const next = new window.Date(date);
    next.setDate(next.getDate() + 1);
    setDate(next);
  };

  const slots = Object.keys(availableTimes)
    // Turn timestamps into Dates
    .map(key => {
      const ts = availableTimes[key];
      const from = new window.Date(ts.from * 1000);
      const to = new window.Date(ts.to * 1000);

      if (weekNum > 0) {
        from.setDate(from.getDate() + weekNum * 7);
        to.setDate(to.getDate() + weekNum * 7);
      }

      return { key, from, to };
    })
    .sort((a, b) => a.from.getTime() - b.from.getTime())
    .filter(d => d.from.getTime() > minDate.getTime());

  const modifiers = {
    disabled: d => {
      const dayName = days[d.getDay()];
      return availability[dayName] == null;
    },
  };

  return (
    <TimeWrapper>
      <Heading>
        <Prev onClick={prevDay} disabled={prevDisabled} />
        <Day onClick={showDatePicker}>
          {date.toLocaleDateString(locale, {
            weekday: "long",
            day: "numeric",
            month: "long",
          })}
          <Arrow direction={datePickerVisible ? "up" : "down"} />
        </Day>
        <Next onClick={nextDay} />
        {datePickerVisible && (
          <DatePickerWrapper>
            <DatePickerCalendar
              minimumDate={nextAvailableDay}
              date={date}
              locale={getLocale(locale)}
              modifiers={modifiers}
              onDateChange={d => {
                setDate(d);
                setDatePickerVisible(false);
              }}
            />
          </DatePickerWrapper>
        )}
      </Heading>
      <PillWrapper>
        {slots.length ? (
          slots.map(s => {
            return (
              <TimeSlot
                onClick={() => {
                  onSelect({
                    key: s.key,
                    from: s.from.getTime(),
                    to: s.to.getTime(),
                  });
                }}
                key={`${s.from}${s.to}`}
              >
                <SlotName>{timeCopy[s.key]}</SlotName>
                {formatTimeRange(s.from, s.to, timezone, locale)}
              </TimeSlot>
            );
          })
        ) : (
          <NoTimesAvailable />
        )}
      </PillWrapper>
    </TimeWrapper>
  );
};

const ErrorBoxWrapper = styled.div`
  margin-bottom: 16px;
  a {
    color: inherit;
  }
`;

const Time = ({ next }) => {
  const [time, setTime] = useRecoilState(formTimeState);
  const handleTimeSelect = selectedTime => {
    setTime(selectedTime);
  };
  const [prompt, tooManyRequestsError, tooManyRequestsErrorLink] = useCopy([
    "bookNow.steps.time.prompt",
    "bookNow.tooManyRequestsError.message",
    "bookNow.tooManyRequestsError.link",
  ]);
  const showTimePicker = time?.from == null;

  const userData = useRecoilValue(userState);
  const requestHistory = useRecoilValue(requestHistoryState);
  const unexpiredRequests = useRecoilValue(unexpiredRequestsState);
  const tooManyRequests = unexpiredRequests && unexpiredRequests.length >= 3;

  return (
    <div>
      {tooManyRequests && !showTimePicker && (
        <ErrorBoxWrapper>
          <ErrorBox>
            {swapCopyVariables(tooManyRequestsError, {
              NUM_REQUESTS: requestHistory.length,
              CLIENT_EMAIL: userData.email,
              LINK_TEXT: (
                <a
                  href={`${process.env.REACT_APP_CLIENT_URL}/requests/`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {tooManyRequestsErrorLink}
                </a>
              ),
            })}
          </ErrorBox>
        </ErrorBoxWrapper>
      )}
      <Prompt bold>{prompt}</Prompt>
      {showTimePicker ? (
        <TimeBox onSelect={handleTimeSelect} />
      ) : (
        <ConfirmTime next={next} disabled={tooManyRequests} />
      )}
    </div>
  );
};

export default Time;
