import { useState } from "react";
import styled from "styled-components";
import { CognitoUser } from "amazon-cognito-identity-js";
import { ErrorBox, TextInput, Link } from "@sussex/react-kit/elements";
import { Loading } from "@sussex/react-kit/assets";
import useCopy from "../../hooks/useCopy";
import userPool from "../../cognito";
import MemoryStorage from "../../memory-storage";
import { Heading, Container, ErrorText, Submit } from "./Elements";
import Confirm from "../Confirm";
import { getUserStatus } from "../../httpapi";

const LinkText = styled(Link)`
  margin-top: 20px;
  font-size: ${({ theme }) => theme.fontSize.normal};
`;

const Instructions = styled.div`
  margin: 25px 0;
  font-size: ${({ theme }) => theme.fontSize.large};
`;

const ForgotPassword = ({ onBack, onSuccess }) => {
  const [
    titleText,
    instructions,
    backText,
    errorText,
    identityVerificationTitle,
    identityVerificationMessage,
    emailText,
    submitText,
  ] = useCopy([
    "bookNow.forgotPassword.title",
    "bookNow.forgotPassword.instructions",
    "bookNow.forgotPassword.back",
    "bookNow.forgotPassword.sendCodeError",
    "bookNow.forgotPassword.identityVerificationTitle",
    "bookNow.forgotPassword.identityVerificationMessage",
    "bookNow.signIn.email",
    "bookNow.signIn.next",
  ]);
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [confirmationNeeded, setConfirmationNeeded] = useState(false);

  const userData = {
    Username: email,
    Pool: userPool,
    Storage: MemoryStorage,
  };

  const cognitoUser = new CognitoUser(userData);

  const sendConfirmationCode = () => {
    cognitoUser.resendConfirmationCode((err, result) => {
      if (err) {
        console.error(err);
        setError(errorText);
        setLoading(false);
        return;
      }
      setConfirmationNeeded(true);
    });
  };

  const sendForgotPasswordCode = () => {
    cognitoUser.forgotPassword({
      onSuccess: () => {
        setLoading(false);
        onSuccess(email);
      },
      onFailure: err => {
        setLoading(false);
        if (err) {
          console.error(err);
          setError(errorText);
        }
      },
    });
  };

  const onConfirmationSuccess = () => {
    setConfirmationNeeded(false);
    setTimeout(() => {
      sendForgotPasswordCode();
    }, 1000);
  };

  const handleClick = async () => {
    setLoading(true);

    const status = await getUserStatus(email);

    if (status === "UNCONFIRMED") {
      sendConfirmationCode();
    } else {
      sendForgotPasswordCode();
    }
  };

  return (
    <Container>
      {
        confirmationNeeded
        ? (
          <>
            <Heading>{identityVerificationTitle}</Heading>
            {error && (
              <ErrorBox>
                <ErrorText>{error}</ErrorText>
              </ErrorBox>
            )}
            <Instructions>{identityVerificationMessage}</Instructions>
            <Confirm
              authDetails={{ email }}
              user={cognitoUser}
              next={onConfirmationSuccess}
              skipLogin={true}
            />
          </>
        )
        : (
          <>
            <Heading>{titleText}</Heading>
            {error && (
              <ErrorBox>
                <ErrorText>{error}</ErrorText>
              </ErrorBox>
            )}
            <Instructions>{instructions}</Instructions>
            <TextInput
              label={emailText}
              value={email}
              onChange={({ target }) => setEmail(target.value)}
            />
            <Submit type="submit" disabled={email === ""} onClick={handleClick}>
              {loading ? <Loading /> : `${submitText}`}
            </Submit>
          </>
        )
      }
      <LinkText onClick={onBack}>{backText}</LinkText>
    </Container>
  );
};

export default ForgotPassword;
