import styled from "styled-components";

import { H2, Button } from "@sussex/react-kit/elements";

export const Heading = styled(H2)`
  margin: 16px 0;
`;

export const Container = styled.div`
  flex: 1;
  > * {
    margin: 8px 0;
  }
`;

export const ErrorText = styled.div`
  margin: 0 15px;
`;

export const Submit = styled(Button)`
  width: 100%;
  height: 56px;
`;
