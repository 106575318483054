import { atom, selector } from "recoil";

export const STEPS = {
  TIME: "Time",
  THERAPY_NEEDS: "TherapyNeeds",
  PAYMENT_METHOD: "PaymentMethod",
  COMMENTS: "Comments",
  SIGN_UP: "SignUp",
  SUMMARY: "Summary",
  COMPLETE: "Complete",
};

export const summaryState = atom({
  key: "FormSummary", // unique ID (with respect to other atoms/selectors)
  default: false, // default value (aka initial value)
});

export const stepState = atom({
  key: "FormStep", // unique ID (with respect to other atoms/selectors)
  default: null, // default value (aka initial value)
});

export const formState = atom({
  key: "FormData", // unique ID (with respect to other atoms/selectors)
  default: {
    time: "",
    issues: [],
    otherIssue: "",
    paymentMethod: "",
    insurances: [],
    otherInsurance: "",
    otherInsuranceConflict: false,
    outOfNetwork: false,
    outOfNetworkConflict: false,
    message: "",
  }, // default value (aka initial value)
});

export const formHasConflicts = selector({
  key: "FormData.HasConflicts",
  get: ({ get }) => {
    const formData = get(formState);
    if (formData.otherInsuranceConflict) {
      return true;
    }
    for (let i = 0; i < formData.issues.length; i++) {
      if (formData.issues[i].conflict) {
        return true;
      }
    }
    for (let i = 0; i < formData.insurances.length; i++) {
      if (formData.insurances[i].conflict) {
        return true;
      }
    }
    return false;
  },
});
