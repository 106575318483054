import { selector } from "recoil";
import { requestProviderProfile } from "../httpapi";

const provider = selector({
  key: "Provider", // unique ID (with respect to other atoms/selectors)
  get: async () => {
    const params = new URLSearchParams(window.location.search);
    const profileUUID = params.get("profile_uuid");
    const res = await requestProviderProfile(profileUUID);
    if (!res.success) {
      console.error("Failed to fetch provider profile", profileUUID);
      return {};
    }
    return res.profile;
  },
});

export default provider;
