import { selector } from "recoil";
import providerState from "./provider";

const fetchCopy = async (countryCode, languageCode) => {
  const previewMode = document.cookie.indexOf("sussex-app-preview=1") >= 0;
  const viewingMode = previewMode ? "draft" : "published";
  try {
    const url = `${
      process.env.REACT_APP_COPY_GROUPS_CDN_URL
    }/booknow-app/psychologytoday/therapists/${viewingMode}/${countryCode.toLowerCase()}/${languageCode.toLowerCase()}.json`;
    const response = await fetch(url);
    const rawCopy = await response.json();
    const copy = rawCopy.reduce((accum, curr) => {
      accum[curr.key] = curr.value;
      return accum;
    }, {});
    return copy;
  } catch (err) {
    throw new Error("Failed to fetch copy", err);
  }
};

const copy = selector({
  key: "Copy", // unique ID (with respect to other atoms/selectors)
  default: "", // default value (aka initial value)
  get: async ({ get }) => {
    try {
      const { countryCode, languageCode } = get(providerState);
      return await fetchCopy(countryCode, languageCode);
    } catch (err) {
      return await fetchCopy("US", "en");
    }
  },
});

export default copy;
