import { Suspense } from "react";
import useCopy from "../hooks/useCopy";

const Message = () => {
  const [message] = useCopy(["bookNow.pageNotFound"]);
  return <h3>{message}</h3>;
};

export default function NotFoundView() {
  return (
    <Suspense fallback={<></>}>
      <Message />
    </Suspense>
  );
}
