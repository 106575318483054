import { useRecoilValue } from "recoil";
import styled from "styled-components";
import { swapCopyVariables } from "@sussex/react-kit/utils";
import { Link } from "@sussex/react-kit/elements";
import providerState from "../../../state/provider";
import useCopy from "../../../hooks/useCopy";
import Prompt from "../Prompt";

const Name = styled.span`
  font-family: ${({ theme }) => theme.fonts.semiBold};
`;

const Complete = () => {
  const { name } = useRecoilValue(providerState);
  const [prompt, confirmationInstructions, returnText] = useCopy([
    "bookNow.steps.complete.prompt",
    "bookNow.steps.complete.confirmationInstructions",
    "bookNow.steps.complete.return",
  ]);

  return (
    <>
      <Prompt>
        {swapCopyVariables(prompt, { NAME: <Name>{name}</Name> })}
      </Prompt>
      <Prompt>{confirmationInstructions}</Prompt>
      <Link>{swapCopyVariables(returnText, { NAME: name })}</Link>
    </>
  );
};

export default Complete;
