import { useState } from "react";
import SignIn from "./SignIn";
import ForgotPassword from "./ForgotPassword";
import ResetPassword from "./ResetPassword";

const SignInFlow = ({ next, canSkip, back }) => {
  const [currentStep, setCurrentStep] = useState("sign-in");
  const [email, setEmail] = useState("");

  switch (currentStep) {
    case "sign-in":
      return (
        <SignIn
          next={next}
          canSkip={canSkip}
          back={back}
          forgotPasswordAction={() => setCurrentStep("forgot-password")}
        />
      );
    case "forgot-password":
      return (
        <ForgotPassword
          onSuccess={clientEmail => {
            setEmail(clientEmail);
            setCurrentStep("otp");
          }}
          onBack={() => setCurrentStep("sign-in")}
        />
      );
    case "otp":
      return (
        <ResetPassword
          email={email}
          onBack={() => setCurrentStep("sign-in")}
        />
      );
    default:
      return null;
  }
};

export default SignInFlow;
