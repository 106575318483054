import { useEffect, useRef, useState, useCallback } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import styled from "styled-components";
import { swapCopyVariables } from "@sussex/react-kit/utils";
import {
  Comments,
  Complete,
  PaymentMethod,
  Time,
  SignUp,
  Summary,
  TherapyNeeds,
} from "./Steps";
import Header from "./Header";
import Panel from "../Panel";
import { stepState, summaryState, STEPS } from "./state";
import userState from "../../state/user";
import providerState from "../../state/provider";
import useCopy from "../../hooks/useCopy";
import { useAddMetric } from "../../providers/MetricProvider";
import Info from "../../assets/Info";

const Form = styled.form`
  flex: 1;
  width: 100%;
`;

const Wrapper = styled.div`
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  animation: fadeIn 0.65s;
  padding-bottom: ${({ footerHeight }) => footerHeight + "px"};
`;

const MessageWrapper = styled.div`
  padding-bottom: 24px;
  a {
    color: inherit;
  }
`;

const MessageBox = styled.div`
  background: #fcf8e2;
  color: inherit;
  padding: 16px 26px;
  border-radius: 10px;
  display: flex;
`;

const MessageIcon = styled.div`
  width: 40px;
`;

const MessageContent = styled.div`
  margin: 0 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
`;

const Message = ({ children }) => {
  return (
    <MessageWrapper>
      <MessageBox>
        <MessageIcon>
          <Info />
        </MessageIcon>
        <MessageContent>{children}</MessageContent>
      </MessageBox>
    </MessageWrapper>
  );
};

const StepForm = () => {
  const [currentStep, setCurrentStep] = useRecoilState(stepState);
  const summary = useRecoilValue(summaryState);
  const user = useRecoilValue(userState);
  const { id, name, freeConsultation } = useRecoilValue(providerState);
  const footerRef = useRef(null);
  const [footerMounted, setFooterMounted] = useState(false);
  const [footerBody, setFooterBody] = useState(null);
  const [footerHeight, setFooterHeight] = useState(0);
  const footerCbRef = useCallback(node => {
    footerRef.current = node;
    setFooterMounted(node !== null);
  }, []);
  const addMetric = useAddMetric();

  const [
    timeTitle,
    therapyNeedsTitle,
    paymentTitle,
    commentsTitle,
    signUpTitle,
    summaryTitle,
    completeTitle,
    notEnabledMessage,
    notEnabledDefaultName,
  ] = useCopy([
    "bookNow.steps.time.title",
    "bookNow.steps.therapyNeeds.title",
    "bookNow.steps.payment.title",
    "bookNow.steps.comments.title",
    "bookNow.signUp.title",
    "bookNow.steps.summary.title",
    "bookNow.steps.complete.title",
    "bookNow.therapistNotEnabled.message",
    "bookNow.therapistNotEnabled.defaultName",
  ]);

  const formStates = {
    [STEPS.TIME]: {
      step: 1,
      component: Time,
      title: timeTitle,
      next: () => {
        return STEPS.THERAPY_NEEDS;
      },
    },
    [STEPS.THERAPY_NEEDS]: {
      step: 2,
      component: TherapyNeeds,
      title: therapyNeedsTitle,
      next: () => {
        return STEPS.PAYMENT_METHOD;
      },
    },
    [STEPS.PAYMENT_METHOD]: {
      step: 3,
      component: PaymentMethod,
      title: paymentTitle,
      next: () => {
        return STEPS.COMMENTS;
      },
    },
    [STEPS.COMMENTS]: {
      step: 4,
      component: Comments,
      title: commentsTitle,
      next: () => {
        if (user.signedIn && user.verified) {
          return STEPS.SUMMARY;
        }
        return STEPS.SIGN_UP;
      },
    },
    [STEPS.SIGN_UP]: {
      step: 5,
      component: SignUp,
      title: signUpTitle,
      next: () => {
        return STEPS.SUMMARY;
      },
    },
    [STEPS.SUMMARY]: {
      step: 6,
      component: Summary,
      title: summaryTitle,
      next: () => {
        return STEPS.COMPLETE;
      },
    },
    [STEPS.COMPLETE]: {
      component: Complete,
      title: completeTitle,
      next: () => {},
    },
  };

  const activeStep = formStates[currentStep];
  const StepComponent = activeStep?.component;

  const handleNext = async () => {
    // Always return to summary if set
    if (summary && currentStep !== STEPS.SUMMARY) {
      setCurrentStep(STEPS.SUMMARY);
      return;
    }
    const nextStep = formStates[currentStep].next();
    setCurrentStep(nextStep);
  };

  useEffect(() => {
    setCurrentStep(STEPS.TIME);
  }, [setCurrentStep]);

  useEffect(() => {
    if (currentStep) {
      addMetric({
        name: "BookNowRequestStep",
        value: currentStep,
      });
    }
  }, [currentStep, addMetric]);

  // The following two useEffects are necessary to track the footer height.
  useEffect(() => {
    const footerEl = footerRef.current;
    if (!footerEl) {
      return;
    }
    const obs = new MutationObserver(() => {
      setFooterBody(footerEl.children.length ? footerEl.children[0] : null);
    });
    obs.observe(footerEl, { childList: true });
    return () => {
      obs.disconnect();
    };
  }, [footerMounted]);

  useEffect(() => {
    if (!footerBody) {
      setFooterHeight(0);
      return;
    }
    const obs = new ResizeObserver(() => {
      setFooterHeight(footerBody.getBoundingClientRect().height);
    });
    obs.observe(footerBody);
    return () => {
      obs.disconnect();
    };
  }, [footerBody]);

  if (!activeStep) {
    return null;
  }

  return (
    <Form onSubmit={e => e.preventDefault()}>
      <Panel>
        <>
          <Header
            currentStep={activeStep.step}
            totalSteps={6}
            title={activeStep.title}
          />
          {!id || !freeConsultation ? (
            <Message>
              {swapCopyVariables(notEnabledMessage, {
                NAME: name || notEnabledDefaultName,
              })}
            </Message>
          ) : (
            <Wrapper footerHeight={footerHeight} key={activeStep.step}>
              <StepComponent footerRef={footerRef} next={handleNext} />
            </Wrapper>
          )}
        </>
      </Panel>
      <div ref={footerCbRef} />
    </Form>
  );
};

export default StepForm;
