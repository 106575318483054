import { selector } from "recoil";
import providerState from "./provider";

const days = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
];

const minDate = new window.Date();
minDate.setTime(minDate.getTime() + 24 * 60 * 60 * 1000);

const nextAvailableDayState = selector({
  key: "Time.NextAvailableDay",
  get: async ({ get }) => {
    const { availability } = await get(providerState);

    const todayIndex = new window.Date().getDay();

    const orderedDays = days
      .slice(todayIndex)
      .concat(days.slice(0, todayIndex));

    const nextDay = orderedDays.findIndex(d => {
      if (availability[d] == null) {
        return false;
      }
      const hasValidTime = Object.values(availability[d]).some(({ from }) => {
        const start = new window.Date(from * 1000);
        return start > minDate;
      });

      return hasValidTime;
    });

    if (nextDay === -1) {
      // return today b/c we have to show something
      return new Date();
    }

    const d = new window.Date();
    d.setDate(d.getDate() + nextDay);
    return d;
  },
});

export { days, minDate, nextAvailableDayState };
