import React from "react";
import ReactDOM from "react-dom";
import { ThemeProvider } from "styled-components";
import { Normalize } from "styled-normalize";
import Router from "./router";
import theme from "./theme";
import { datadogInit } from "./datadog";

if (process.env.NODE_ENV !== "development") {
  datadogInit();
}

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Normalize />
      <Router />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root"),
);
