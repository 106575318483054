import { useTheme } from "styled-components";

const Arrow = ({ direction, color }) => {
  const theme = useTheme();
  const fill = color || theme.colors.default;

  let rotation;
  switch (direction) {
    case "up":
      rotation = 180;
      break;
    case "right":
      rotation = 270;
      break;
    case "down":
      rotation = 0;
      break;
    case "left":
      rotation = 90;
      break;
    default:
      rotation = 0;
  }

  return (
    <svg
      width="11px"
      height="7px"
      viewBox="0 0 11 7"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      style={{ transform: `rotate(${rotation}deg)` }}
    >
      <defs></defs>
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g transform="translate(-987.000000, -32.000000)" fill={fill}>
          <g id="Group-32">
            <g transform="translate(859.000000, 15.687500)">
              <polygon
                id="navigatedown"
                points="133.177885 23 138.355769 17.8221154 137.540865 17 133.177885 21.3629808 128.814904 17 128 17.8221154"
              ></polygon>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
export default Arrow;
