import { useEffect, Suspense, useState } from "react";
import styled, { withTheme } from "styled-components";
import { Button } from "@sussex/react-kit/elements";
import { Loading as Spinner } from "@sussex/react-kit/assets";
import { swapCopyVariables } from "@sussex/react-kit/utils";
import { useRecoilState, useRecoilValue } from "recoil";
import useCopy from "../../hooks/useCopy";
import { formState } from "../StepForm/state";
import providerState from "../../state/provider";
import userState, {
  requestHistory as requestHistoryState,
} from "../../state/user";
import { getIDPCredentials, decryptText } from "../../idp";

const Text = styled.div`
  line-height: 1.4;
  font-family: ${({ theme, bold }) =>
    bold ? theme.fonts.semiBold : theme.fonts.primary};
  font-size: ${({ theme }) => theme.fontSize.large};
`;

const Title = styled(Text)`
  font-family: ${({ theme }) => theme.fonts.semiBold};
  margin: 0;
`;

const PrevRequestInstructions = styled(Text)`
  margin: 24px 0 0;
  & > span {
    font-family: ${({ theme }) => theme.fonts.semiBold};
  }
`;

const RequestWrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.border};
  padding: 16px;
  border-radius: 10px;
  margin-top: 24px;
  display: flex;
  align-items: center;
`;

const Photo = styled.img`
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  justify-self: flex-start;
  margin-right: 8px;
`;

const RequestInfo = styled(Text)`
  justify-self: flex-start;
  flex: 1;
`;

const SelectButton = styled(Button)`
  height: 40px;
  min-width: 70px;
  flex: 0;
  font-family: ${({ theme }) => theme.fonts.semiBold};
  justify-self: flex-end;
`;

const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 16px;
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 10px;
  font-family: ${({ theme }) => theme.fonts.semiBold};
  font-size: ${({ theme }) => theme.fontSize.normal};
  gap: 8px;
  margin-top: 24px;
`;

const RequestList = ({ onSkip, onSelect, disabled }) => {
  const providerData = useRecoilValue(providerState);
  const allRequests = useRecoilValue(requestHistoryState);
  const [formData, setFormData] = useRecoilState(formState);
  const [processing, setProcessing] = useState(false);

  // Only keep most recent request for each profile
  const requestHistory = allRequests.reduce((accum, curr) => {
    const found = accum.find(r => r.profileUuid === curr.profileUuid);
    if (!found) {
      accum.push(curr);
    }
    return accum;
  }, []);

  const hasRequestHistory = requestHistory && requestHistory.length > 0;

  const [
    prevRequestItemText,
    selectText,
    prevRequestSkipTitle,
    prevRequestSkipText,
  ] = useCopy([
    "bookNow.previousRequest.requestItem",
    "bookNow.previousRequest.selectButton",
    "bookNow.previousRequest.skipTitle",
    "bookNow.previousRequest.skipBody",
  ]);

  const handleSelect = async request => {
    if (processing) {
      return;
    }

    setProcessing(true);

    const attributeExists = (attrs, id) => {
      const index = attrs.findIndex(a => a.id === id);
      return index >= 0;
    };

    const credentials = await getIDPCredentials();
    const decryptedMessage = await decryptText(credentials, request.message);

    const previousFormData = {
      issues: [...request.issues],
      otherIssue: request.otherIssue,
      paymentMethod: request.paymentMethod,
      insurances: [...request.insurances],
      otherInsurance: request.otherInsurance,
      message: decryptedMessage,
      otherInsuranceConflict: false,
    };

    // Set conflicting form values:
    previousFormData.issues.forEach((issue, i) => {
      if (!attributeExists(providerData.issues, issue.id)) {
        previousFormData.issues[i] = {
          ...issue,
          conflict: true,
        };
      }
    });
    previousFormData.insurances.forEach((ins, i) => {
      if (!attributeExists(providerData.insurances, ins.id)) {
        previousFormData.insurances[i] = {
          ...ins,
          conflict: true,
        };
      }
    });
    // Set conflict if write-in insurance exists and provider supports out-of-network.
    if (providerData.outOfNetwork && previousFormData.otherInsurance) {
      previousFormData.otherInsuranceConflict = true;
    }
    // Set conflict if out-of-network exists and provider does not support it.
    if (!providerData.outOfNetwork && previousFormData.outOfNetwork) {
      previousFormData.outOfNetworkConflict = true;
    }
    setFormData({ ...formData, ...previousFormData });
    setProcessing(false);
    onSelect();
  };

  // Put this in a useEffect to prevent batcher warning:
  useEffect(() => {
    if (!hasRequestHistory) {
      onSkip();
    }
  }, [onSkip, hasRequestHistory]);

  if (!hasRequestHistory) {
    return null;
  }

  return (
    <>
      {requestHistory.map(r => (
        <RequestWrapper key={r.title + r.id}>
          <Photo src={r.profilePhotoUrl} alt={r.profileName} />
          <RequestInfo>
            {swapCopyVariables(prevRequestItemText, {
              NAME: <Text bold>{r.profileName}</Text>,
            })}
          </RequestInfo>
          <SelectButton disabled={disabled} onClick={() => handleSelect(r)}>
            {processing ? (
              <Spinner outerColor="white" innerColor="transparent" />
            ) : (
              selectText
            )}
          </SelectButton>
        </RequestWrapper>
      ))}
      <RequestWrapper>
        <RequestInfo>
          <Text bold>{prevRequestSkipTitle}</Text>
          <Text>{prevRequestSkipText}</Text>
        </RequestInfo>
        <SelectButton disabled={disabled} onClick={onSkip}>
          {selectText}
        </SelectButton>
      </RequestWrapper>
    </>
  );
};

const Loading = withTheme(({ theme }) => {
  const [loadingText] = useCopy(["bookNow.global.loading"]);
  return (
    <LoadingWrapper>
      <Spinner outerColor={theme.colors.default} innerColor="transparent" />
      {loadingText}
    </LoadingWrapper>
  );
});

const PreviousRequests = ({ onSkip, onSelect, disabled }) => {
  const userData = useRecoilValue(userState);
  const providerData = useRecoilValue(providerState);
  const [prevRequestTitle, prevRequestPrompt] = useCopy([
    "bookNow.previousRequest.title",
    "bookNow.previousRequest.prompt",
  ]);

  return (
    <>
      <Title>
        {swapCopyVariables(prevRequestTitle, { NAME: userData.name })}
      </Title>
      <PrevRequestInstructions>
        {swapCopyVariables(prevRequestPrompt, {
          NAME: <span>{providerData.name}</span>,
        })}
      </PrevRequestInstructions>
      <Suspense fallback={<Loading />}>
        <RequestList disabled={disabled} onSkip={onSkip} onSelect={onSelect} />
      </Suspense>
    </>
  );
};

export default PreviousRequests;
