import { useState, useRef } from "react";
import { TextInput, Pill } from "@sussex/react-kit/elements";
import styled from "styled-components";
import { selector, useRecoilState, useRecoilValue } from "recoil";
import providerState from "../../../state/provider";
import useCopy from "../../../hooks/useCopy";
import { formState } from "../state";
import Footer from "../Footer";

export const formNeedsState = selector({
  key: "FormData.Needs",
  get: ({ get }) => get(formState).issues,
  set: ({ set }, newValue) => set(formState, s => ({ ...s, issues: newValue })),
});

export const formOtherNeedsState = selector({
  key: "FormData.OtherNeeds",
  get: ({ get }) => get(formState).otherIssue,
  set: ({ set }, newValue) =>
    set(formState, s => ({ ...s, otherIssue: newValue })),
});

const PillWrapper = styled.div`
  * {
    margin: 16px 0;
  }
`;

const OtherInput = styled(TextInput)`
  &::placeholder {
    color: ${({ theme }) => theme.colors.lightGray};
  }
`;

const Speciality = ({ name, selected, onClick }) => {
  return (
    <Pill onClick={onClick} selected={selected}>
      {name}
    </Pill>
  );
};

const TherapyNeeds = ({ footerRef, next }) => {
  const [otherText, otherPlaceholderText] = useCopy([
    "bookNow.steps.other",
    "bookNow.steps.otherPlaceholder",
  ]);
  const otherRef = useRef(null);
  const [needs, setNeeds] = useRecoilState(formNeedsState);
  const [otherNeed, setOtherNeed] = useRecoilState(formOtherNeedsState);
  const [localNeeds, setLocalNeeds] = useState(needs);
  const { issues } = useRecoilValue(providerState);
  const [showOther, setShowOther] = useState(!!otherNeed);
  const toggleNeed = s => {
    const shouldRemove = localNeeds.includes(s);

    if (shouldRemove) {
      setLocalNeeds(localNeeds.filter(x => x !== s));
      return;
    }

    setLocalNeeds(localNeeds.concat([s]));
  };
  const setOther = value => {
    if (value.length > 50) {
      return;
    }
    setOtherNeed(value);
  };
  const handleNext = () => {
    // Remove any conflicts
    const needs = localNeeds.filter(n => !n.conflict);

    setNeeds(needs);
    next();
  };
  const handleCancel = () => {
    next();
  };
  const toggleOther = () => {
    if (showOther) {
      setOther("");
    }
    if (!showOther) {
      setTimeout(() => otherRef.current.scrollIntoView({ behavior: "smooth" }));
    }
    setShowOther(!showOther);
  };

  return (
    <>
      <PillWrapper>
        {issues.map(s => (
          <Speciality
            name={s.title}
            key={s.id}
            selected={localNeeds.includes(s)}
            onClick={() => toggleNeed(s)}
          />
        ))}
        <Pill selected={showOther} onClick={toggleOther}>
          {otherText}
        </Pill>
        {showOther && (
          <OtherInput
            ref={otherRef}
            value={otherNeed}
            placeholder={otherPlaceholderText}
            maxlength="50"
            onChange={({ target }) => setOther(target.value)}
          />
        )}
      </PillWrapper>
      <Footer next={handleNext} cancel={handleCancel} footerRef={footerRef} />
    </>
  );
};

export default TherapyNeeds;
