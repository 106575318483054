import { Suspense, useEffect } from "react";
import { useSetRecoilState } from "recoil";
import styled from "styled-components";
import StepForm from "../components/StepForm";
import Loading from "../components/Loading";
import userState from "../state/user";
import { getCurrentUser } from "../httpapi";
import MetricProvider from "../providers/MetricProvider";

const Wrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const FormWrapper = styled.div`
  overflow: hidden;
  display: flex;
  flex: 1;
`;

const FormFallback = () => {
  return <Loading />;
};

export default function LandingView() {
  const setUserState = useSetRecoilState(userState);

  useEffect(() => {
    const loginCheck = async () => {
      const user = await getCurrentUser();

      if (user) {
        setUserState({
          signedIn: true,
          verified: user.emailVerified,
          username: user.email,
          name: user.name,
          email: user.email,
          phone: user.phoneNumber,
        });
      }
    };

    loginCheck();
  }, [setUserState]);

  return (
    <Wrapper>
      <MetricProvider>
        <FormWrapper>
          <Suspense fallback={<FormFallback />}>
            <StepForm />
          </Suspense>
        </FormWrapper>
      </MetricProvider>
    </Wrapper>
  );
}
