import styled, { createGlobalStyle } from "styled-components";
import { RecoilRoot } from "recoil";

import { DevPanel } from "@sussex/react-kit/dev";

const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${({ theme }) => theme.colors.light};
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
  }
`;

const AppContainer = styled.div`
  display: block;
  color: ${({ theme }) => theme.colors.default};
  width: 100%;
  font-family: ${({ theme }) => theme.fonts.primary};
`;

const App = ({ children }) => {
  return (
    <RecoilRoot>
      <AppContainer>
        <GlobalStyle />
        {process.env.REACT_APP_ENVIRONMENT === "dev" && (
          <DevPanel wiremockURI={process.env.REACT_APP_API_URL} />
        )}
        {children}
      </AppContainer>
    </RecoilRoot>
  );
};

export default App;
