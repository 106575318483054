import { atom, selector } from "recoil";
import providerState from "./provider";
import { requestPreviousRequests, requestProviderProfile } from "../httpapi";
import { STATUSES_ACTIVE } from "@sussex/react-kit/conversations";

const user = atom({
  key: "User", // unique ID (with respect to other atoms/selectors)
  default: {
    username: null,
    signedIn: false,
    verified: false,
    name: "",
    phone: "",
    email: "",
    locale: "",
  }, // default value (aka initial value)
});

const getProfiles = async profileUuids => {
  const profileRequests = profileUuids.map(p => requestProviderProfile(p));
  const profiles = await Promise.all(profileRequests);
  return profiles;
};

const unexpiredRequests = selector({
  key: "User.UnexpiredRequests",
  get: ({ get }) => {
    const history = get(requestHistory);

    if (!history) {
      return [];
    }

    const now = Math.floor(Date.now() / 1000);
    return history.filter(r => {
      if (STATUSES_ACTIVE.indexOf(r.status) === -1) {
        return false;
      }

      if (r.proposedDatetime > 0) {
        if (r.proposedDatetime < now) {
          return false;
        }
      } else if (r.datetime < now) {
        return false;
      }

      return true;
    });
  },
});

const activeRequests = selector({
  key: "User.ActiveRequests",
  get: ({ get }) => {
    const history = get(requestHistory);

    if (!history) {
      return [];
    }

    return history.filter(r => STATUSES_ACTIVE.indexOf(r.status) >= 0);
  },
});

const requestHistory = selector({
  key: "User.RequestHistory",
  get: async ({ get }) => {
    if (!get(user).signedIn || !get(user).verified) {
      return null;
    }
    try {
      const reqRes = await requestPreviousRequests();
      if (!reqRes.success) {
        return null;
      }

      // Get all requests and ensure they are in order of new to old
      const requests = reqRes.requests.sort(
        (a, b) => a.createdAt > b.createdAt,
      );

      // Get profile data for each request
      const distinctProfiles = requests.reduce((accum, curr) => {
        const found = accum.find(uuid => uuid === curr.profileUuid);
        if (!found) {
          accum.push(curr.profileUuid);
        }
        return accum;
      }, []);

      const profiles = await getProfiles(distinctProfiles);
      const result = [];

      requests.forEach((r, i) => {
        const p = profiles.find(p => p.profile.profileUuid === r.profileUuid);

        if (p) {
          r.profilePhotoUrl = p.profile.photoUrl;
          r.profileName = p.profile.name;
          result.push(r);
        }
      });

      return result;
    } catch (e) {
      console.error(e);
      return null;
    }
  },
});

const locale = selector({
  key: "User.Locale",
  get: async ({ get }) => {
    const userData = get(user);
    if (userData.locale) {
      return userData.locale;
    }
    const providerData = get(providerState);
    if (providerData.languageCode && providerData.countryCode) {
      return `${providerData.languageCode}-${providerData.countryCode}`;
    }
    return navigator.language;
  },
});

export default user;

export { requestHistory, activeRequests, unexpiredRequests, locale };
