const isIframe = window.self !== window.top;
const isPopup = !!window.opener;

export const postMessageToParent = msg => {
  let opener = null;
  if (isIframe) {
    opener = window.parent;
  }
  if (isPopup) {
    opener = window.opener;
  }
  if (!opener) {
    return;
  }
  opener.postMessage(msg, "*");
};

export const close = () => {
  console.log("TODO: close");
};

export const obfuscateEmail = email => {
  if (!email) {
    return;
  }
  const parts = email.split("@");
  const name = parts[0];
  return (
    name[0] +
    "*".repeat(name.length - 2) +
    name[name.length - 1] +
    "@" +
    parts[1]
  );
};

export const uuid = () => {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16),
  );
};
