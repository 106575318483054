import { useState } from "react";
import styled from "styled-components";
import { selector, useRecoilState } from "recoil";
import { TextArea } from "@sussex/react-kit/elements";
import { swapCopyVariables } from "@sussex/react-kit/utils";
import { formState } from "../state";
import useCopy from "../../../hooks/useCopy";
import Prompt from "../Prompt";
import Footer from "../Footer";

export const formCommentsState = selector({
  key: "FormData.Comments",
  get: ({ get }) => get(formState).message,
  set: ({ set }, newValue) =>
    set(formState, s => ({ ...s, message: newValue })),
});

const CharCounter = styled.div`
  font-family: ${({ theme }) => theme.fonts.semiBold};
  font-size: ${({ theme }) => theme.fontSize.normal};
  line-height: 1;
  padding-top: 10px;
  text-align: right;
  color: ${({ theme, over }) => (over ? theme.colors.red : theme.colors.green)};
`;

const Instructions = styled.div`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.fontSize.large};
  line-height: 1.4;
  color: ${({ theme }) => theme.colors.gray};
  p {
    margin: 0;
    padding-top: 24px;
  }
`;

const Comments = ({ next, footerRef }) => {
  const charLimit = 1500;
  const [comments, setComments] = useRecoilState(formCommentsState);
  const [localComments, setLocalComments] = useState(comments);
  const [prompt, characterCounter, instructions] = useCopy([
    "bookNow.steps.comments.prompt",
    "bookNow.steps.comments.characterCounter",
    "bookNow.steps.comments.instructions",
  ]);

  const handleChange = e => {
    setLocalComments(e.target.value);
  };

  const handleNext = () => {
    setComments(localComments);
    next();
  };

  const handleCancel = () => {
    next();
  };

  return (
    <>
      <Prompt bold>{prompt}</Prompt>
      <TextArea
        onChange={handleChange}
        maxLength={charLimit}
        rows="8"
        value={localComments}
      />
      <CharCounter over={localComments.length >= charLimit}>
        {swapCopyVariables(characterCounter, {
          COUNT: localComments.length,
          LIMIT: charLimit,
        })}
      </CharCounter>
      <Instructions dangerouslySetInnerHTML={{ __html: instructions }} />
      <Footer footerRef={footerRef} next={handleNext} cancel={handleCancel} />
    </>
  );
};

export default Comments;
