import styled from "styled-components";

const Wrapper = styled.div`
  background-color: white;
  padding: 0 25px;
  overflow: auto;
  height: 100%;
`;

const Panel = ({ children }) => {
  return <Wrapper>{children}</Wrapper>;
};

export default Panel;
